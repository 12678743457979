<div class="keylocaker-container">

    <div class="details-area">

        <div class="entries" *ngIf="currentView == Views.entries" style="height: 77%;">

            <div class="top-bar">

                <div class="icon" (click)="changeInternalViews('entriesChart')">
                    <img *ngIf="!showEntriesChart" src="./assets/key-locker/top-bar/icon-1.png">
                    <img *ngIf="showEntriesChart" src="./assets/key-locker/top-bar/icon-1-blue.png">
                </div>
        
                <!-- <div class="icon" (click)="changeInternalViews('duplexCharts')">
                    <img *ngIf="!showDuplexCharts" src="./assets/key-locker/top-bar/icon-2.png">
                    <img *ngIf="showDuplexCharts" src="./assets/key-locker/top-bar/icon-2-blue.png">
                </div> -->
        
                <div class="icon" (click)="changeInternalViews('entriesGrid')">
                    <img *ngIf="!showEntriesGrid" src="./assets/key-locker/top-bar/icon-3.png">
                    <img *ngIf="showEntriesGrid" src="./assets/key-locker/top-bar/icon-3-blue.png">
                </div>
        
            </div>

            <p-tabView *ngIf="showEntriesGrid">

                <p-tabPanel header="Your Active Bidding Rooms">
                   
                    <div class="common-ag-grid-styles" style="width: 70%; height: 100%;">
                    
                        <ag-grid-angular #activeAgGrid 
                            style="width: 100%; height: 100%; font-size: 12px;" 
                            class="ag-theme-alpine"
                            [rowData]="activeRowData"
                            [columnDefs]="activeColumnDefs"
                            (cellClicked)="onCellClicked($event)"
                        >
                        </ag-grid-angular>
    
                    </div>
    
    
                </p-tabPanel>
    
                <p-tabPanel header="Closed Stats">
                    
                    <div class="common-ag-grid-styles" style="width: 70%; height: 100%;">
                    
                        <ag-grid-angular #closeAgGrid 
                            style="width: 100%; height: 100%; font-size: 12px;" 
                            class="ag-theme-alpine"
                            [rowData]="closeRowData"
                            [columnDefs]="closeColumnDefs"
                        >
                        </ag-grid-angular>
    
                    </div>
    
                </p-tabPanel>
    
            </p-tabView>

            <div *ngIf="showEntriesChart" style="height: 100%;">

                <div class="header">
                    <label>
                        Number of Entries of Last 12 Months
                    </label>
                </div>
    
                <div class="titles">
    
                    <div class="item">
                        S01
                    </div>
    
                    <div class="item">
                        Username
                    </div>
    
                    <div class="item">
                        Bid $
                    </div>
    
                    <div class="item">
                        Bid #
                    </div>
    
                    <div class="item">
                        Date
                    </div>
    
                    <div class="item">
                        Time
                    </div>
    
                </div>
    
                <p-chart type="bar" [data]="chartData.biddingRooms" [width]="chartWidth" [responsive]="false" [height]="chartHeight" [options]="chartOptions.biddingRooms"></p-chart>
    
            </div>

            <div class="duplex-charts-container" *ngIf="showDuplexCharts" style="height: 100%;">

                <div class="header">

                    <label>
                        Single - Auction House - 01
                    </label>

                    <div class="box-1"></div>

                    <div class="box-2"></div>

                </div>
    
                <div class="duplex-charts">

                    <div class="chart1">

                        <div class="titles">
    
                            <div class="item">
                                S01
                            </div>
            
                            <div class="item">
                                Username
                            </div>
            
                            <div class="item">
                                Bid $
                            </div>
            
                            <div class="item">
                                Bid #
                            </div>
            
                            <div class="item">
                                Date
                            </div>
            
                            <div class="item">
                                Time
                            </div>
            
                        </div>
            
                        <p-chart type="line" [data]="chartData.duplexChart1" [width]="800" [responsive]="false" [height]="chartHeight" [options]="chartOptions.duplexChart1"></p-chart>

                    </div>
 

                    <div class="chart2">

                        <div class="titles">
    
                            <div class="item">
                                01
                            </div>
            
                            <div class="item">
                                Username
                            </div>
            
                            <div class="item">
                                Bid $
                            </div>
            
                            <div class="item">
                                Bid #
                            </div>
            
                            <div class="item">
                                Date
                            </div>
            
                            <div class="item">
                                Time
                            </div>
            
                        </div>
            
                        <p-chart type="line" [data]="chartData.duplexChart2" [width]="800" [responsive]="false" [height]="chartHeight" [options]="chartOptions.duplexChart2"></p-chart>
                        
                    </div>

                </div>

    
            </div>

        </div>

        <div class="cash-spent" *ngIf="currentView == Views.cashSpent" style="height: 77%;">

            <div class="top-bar">

                <div class="icon" (click)="changeInternalViews('auctionChart')">
                    <img *ngIf="!showAcutionChart" src="./assets/key-locker/top-bar/icon-1.png">
                    <img *ngIf="showAcutionChart" src="./assets/key-locker/top-bar/icon-1-blue.png">
                </div>
        
        
                <div class="icon" (click)="changeInternalViews('auctionGrid')">
                    <img *ngIf="!showAuctionGrid" src="./assets/key-locker/top-bar/icon-3.png">
                    <img *ngIf="showAuctionGrid" src="./assets/key-locker/top-bar/icon-3-blue.png">
                </div>
        
            </div>

            <div class="header">
                <label>
                    Cash Spent of Last 12 Months
                </label>
                <!-- <div>

                </div> -->
            </div>

            <div *ngIf="showAcutionChart">
    
                <div class="titles">
    
                    <div class="item">
                        S01
                    </div>
    
                    <div class="item">
                        Username
                    </div>
    
                    <div class="item">
                        Bid $
                    </div>
    
                    <div class="item">
                        Bid #
                    </div>
    
                    <div class="item">
                        Date
                    </div>
    
                    <div class="item">
                        Time
                    </div>
    
                </div>
    
                <p-chart type="bar" [data]="chartData.cashSpent" [width]="chartWidth" [responsive]="false" [height]="chartHeight" [options]="chartOptions.cashSpent"></p-chart>

            </div>
            

            <div *ngIf="showAuctionGrid" class="common-ag-grid-styles" style="width: 70%; height: 100%;">
                    
                <ag-grid-angular #agGrid 
                    style="width: 100%; height: 100%; font-size: 12px;" 
                    class="ag-theme-alpine"
                    [rowData]="cashSpentRowData"
                    [columnDefs]="cashSpentColumnDefs"
                >
                </ag-grid-angular>

            </div>

        </div>

        <div class="invites-area" *ngIf="currentView == Views.invites" style="height: 77%;">
            
            <div class="top-bar">        
        
                <!-- <div class="icon">
                    <img src="./assets/key-locker/top-bar/icon-3-blue.png">
                </div> -->
        
            </div>

            <div class="invites">

                <div class="list-area">

                    <div class="header">
                        Successful Invites
                    </div>
        
                    <div class="sub-header">
                        Sent Invites
                    </div>
        
                    <div class="list">
        
                        <div class="item" *ngFor="let anInvite of invites">
        
                            <div class="details-area">
            
                                <!-- <div class="username">
                                    {{ anInvite.name }}
                                </div> -->
            
                                <div class="email">
                                    {{ anInvite.email }}
                                </div>
            
                            </div>
            
                            <div class="status" [style.color]="anInvite.status == 'Pending' ? '#D9534F' : '#5CB85C'">
                                {{ anInvite.status }}
                            </div>
                        </div>
        
                    </div>
    
    
                </div>
    
                <div class="ref-area">
    
                    <div class="header">
                        Referrals
                    </div>
    
                    <div class="send-invites">
    
                        <div class="title">
                            Refer a friend Give $10, Get $10 
                        </div>
    
                        <div class="info">
                            Spread the Summersalt love! You’ll get $10 for each friend who orders.
                            Only new customers are valid for the referral.
                        </div>
    
                        <div class="act-area">
                            <input id="input" type="text" size="30" pInputText placeholder="Your Friends’ emails (separated by commas)" [(ngModel)]="emailsStr"> 
                            <img src="./assets/key-locker/btn-send.png" (click)="sendInvitation()">
                            
                        </div>
    
                        <div class="line"></div>
    
                        <div class="link-copy">
                            <p-toast position="bottom-right" key="br"></p-toast>
                            <button type="button" pButton pRipple (click)="copyLinkToClipboard()" class="p-button-success" [cdkCopyToClipboard]="url">Copy Link</button>
                        </div>
    
                    </div>
    
                </div>

            </div>






        </div>

        <div class="bidPlaced" *ngIf="currentView == Views.bidPlaced" style="height: 72%;">

            <div class="top-bar">

                <div class="icon" (click)="changeInternalViews('showBidPlacedChart')">
                    <img *ngIf="!showBidPlacedChart" src="./assets/key-locker/top-bar/icon-1.png">
                    <img *ngIf="showBidPlacedChart" src="./assets/key-locker/top-bar/icon-1-blue.png">
                </div>
        
        
                <div class="icon" (click)="changeInternalViews('showBidPlacedGrid')">
                    <img *ngIf="!showBidPlacedGrid" src="./assets/key-locker/top-bar/icon-3.png">
                    <img *ngIf="showBidPlacedGrid" src="./assets/key-locker/top-bar/icon-3-blue.png">
                </div>
        
            </div>

            <div class="header">
                Bids Placed
            </div>

            <div *ngIf="showBidPlacedChart">
                <p-chart type="doughnut" [data]="chartData.bids" [options]="chartOptions.bids" [width]="chartWidth" [height]="chartHeight" [responsive]="false"></p-chart>
            </div>

            <div *ngIf="showBidPlacedGrid" class="common-ag-grid-styles" style="width: 66%; height: 100%;">

                <ag-grid-angular #agGrid 
                    style="width: 100%; height: 100%; font-size: 12px;" 
                    class="ag-theme-alpine"
                    [rowData]="bidPlacedRowData"
                    [columnDefs]="bidPlacedtColumnDefs"
                >
                </ag-grid-angular>

            </div>

        </div>

        <div class="contracts" *ngIf="currentView == Views.contractWon" style="height: 100%;">

            <div class="top-bar">

                <div class="icon" (click)="changeInternalViews('contractChart')">
                    <img *ngIf="!showContractChart" src="./assets/key-locker/top-bar/icon-1.png">
                    <img *ngIf="showContractChart" src="./assets/key-locker/top-bar/icon-1-blue.png">
                </div>
        
                <div class="icon" (click)="changeInternalViews('contractGrid')">
                    <img *ngIf="!showContractDetails" src="./assets/key-locker/top-bar/icon-3.png">
                    <img *ngIf="showContractDetails" src="./assets/key-locker/top-bar/icon-3-blue.png">
                </div>
        
            </div>

            <div *ngIf="showContractChart">

                <div class="header">
                    Contracts Won
                </div>
    
                <p-chart type="bar" [data]="chartData.contractsWon" [width]="chartWidth" [responsive]="false" [height]="chartHeight" [options]="chartOptions.contractsWon"></p-chart>

            </div>

            <div class="contract-details" *ngIf="showContractDetails">

                <div class="summery">

                    <div class="fixed-box" style="border-right: 1px solid rgba(44, 44, 44, 0.2);">
                    
                        <div class="header-text">
                            Contract Value
                        </div>
                        
                        <div class="details">
                            $1,069.56
                        </div>

                    </div>

                    <div class="dynamic-box" style="border-right: 1px solid rgba(44, 44, 44, 0.2);">
                    
                        <div class="header-text">
                            Application Price ( Winning Bid Price )
                        </div>
                        
                        <div class="details">
                            $840.45
                        </div>

                    </div>

                    <div class="fixed-box">

                        <div class="header-text">
                            Pending Clearance
                        </div>
                        
                        <div class="details">
                            $229.11
                        </div>

                    </div>

                </div>

                <div class="tabs-area">
                    
                    <p-tabView>

                        <p-tabPanel header="All">
                           
                            <div class="common-ag-grid-styles" style="width: 98%; height: 100%;">
                            
                                <ag-grid-angular #agGrid 
                                    style="width: 100%; height: 100%; font-size: 12px;" 
                                    class="ag-theme-alpine"
                                    [rowData]="contractRowData"
                                    [columnDefs]="contractColumnDefs"
                                >
                                </ag-grid-angular>
            
                            </div>
            
            
                        </p-tabPanel>
            
                        <p-tabPanel header="Claims">
                            
                            <div class="common-ag-grid-styles" style="width: 98%; height: 100%;">
                            
                                <ag-grid-angular #agGrid 
                                    style="width: 100%; height: 100%; font-size: 12px;" 
                                    class="ag-theme-alpine"
                                    [rowData]="contractRowData"
                                    [columnDefs]="contractColumnDefs"
                                >
                                </ag-grid-angular>
            
                            </div>
            
                        </p-tabPanel>

                        <p-tabPanel header="Claimed">
                            
                            <div class="common-ag-grid-styles" style="width: 98%; height: 100%;">
                            
                                <ag-grid-angular #agGrid 
                                    style="width: 100%; height: 100%; font-size: 12px;" 
                                    class="ag-theme-alpine"
                                    [rowData]="contractRowData"
                                    [columnDefs]="contractColumnDefs"
                                >
                                </ag-grid-angular>
            
                            </div>
            
                        </p-tabPanel>
            
                    </p-tabView>

                </div>

            </div>

        </div>


    </div>

    <div class="cards-area">


        <div class="a-card" *ngFor="let aCard of cards" (click)="viewChange(aCard)" [ngClass]="aCard.background ? 'selected-item' : '' ">

            <div class="icon">
                <img [src]="aCard.icon">
            </div>

            <div class="title">
                {{ aCard.title }}
            </div>

            <div class="info">

                {{ aCard.info }}

                <img *ngIf="aCard.infoIcon" [src]="aCard.infoIcon">

            </div>

        </div>


    </div>

    <div *ngIf="observeUI">
        <app-popup [display]="observeUI" (notifyParent)="recieveFromPopupDialog($event)" [popupData]="popupData[0]" [selectedAuctionId]="selectedAuctionId" [selectedAuctionRoomNumber]="selectedAuctionRoomNumber"></app-popup>
    </div>

    <div *ngIf="reviewUI">
        <app-popup [display]="reviewUI" (notifyParent)="recieveFromPopupDialog($event)" [popupData]="popupData[1]" [selectedClosedAuctionId]="selectedClosedAuctionId" [selectedClosedAuctionRoomNumber]="selectedClosedAuctionRoomNumber"></app-popup>
    </div>

</div>


