import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IMessageStream, Interconnect } from 'ng-interconnect';
import { AppConfigService } from 'src/app/app-config.service';
import { MainViews } from 'src/app/app.types';
import { ExtapiService } from 'src/app/extapi.service';
import { ProfileService } from 'src/app/profile.service';

@Component({
  selector: 'app-add-card-popup',
  templateUrl: './add-card-popup.component.html',
  styleUrls: ['./add-card-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddCardPopupComponent implements OnInit {

  @Input() display: boolean = false;
  @Input() width: number;
  @Input() height: number;

  @Output() closePopup = new EventEmitter<any>();

  private changeView: IMessageStream | Promise<IMessageStream>;

  paymenttype: string = '';

  form;

  constructor(private fb: FormBuilder,
    private interconnect: Interconnect, 
    public profileService: ProfileService,
    public extapiService: ExtapiService,
    public appService: AppConfigService,) {

    // this.form = this.fb.group({
    //   creditCard: [],
    //   creditCardDate: [],
    //   creditCardCvv: [],
    //   creditCardHolder: [],
    // });

    this.form = this.fb.group({
      creditCard: ["4216892700947700"],
      creditCardDate: ["08/26"],
      creditCardCvv: ["800"],
      creditCardHolder: ["Darshana Pathum"],
    });

    this.changeView = interconnect.connectToListener('leftbar/changeView', 'topbar');
    if (this.changeView['then']) {
      this.changeView['then']((notifier) => this.changeView = notifier);
    }

  }

  ngOnInit(): void {
  }

  close(){

    this.display = false;
    this.closePopup.emit();
  }

  async saveCardInfo(){

    var cardDetails = this.form.value;
    console.log(this.form.value)
    localStorage.setItem("ds-debit", JSON.stringify(cardDetails));
    
    try {

      let selectectAuction = this.appService.allAuctions[this.appService.selectedAuctionIdx];
      let date = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ');

      let CheckIfTheUserIsRegisteredForTheAuction = await this.extapiService.CheckIfTheUserIsRegisteredForTheAuction({auctionId: selectectAuction.auction_id,clientId: this.profileService.currentUser.client_id});

      if(CheckIfTheUserIsRegisteredForTheAuction.length === 0)
        await this.extapiService.registerAuction({
          auction_id: selectectAuction.auction_id,
          client_id: this.profileService.currentUser.client_id,
          category: selectectAuction.category,
          room_number: 1,
          enter_fee: selectectAuction.enter_fee,
          date: date});
        alert("successful");
  
      this.close();
      
    }

    catch (error) {

      console.log(error);
      alert("Something went wrong")
      
    }
    

  }

}
