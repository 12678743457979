<div class="container">

    <div class="slider-wrapper">

        <div class="slider-btns">
            <div class="left" (click)="navigate('-1')">
                <img src="./assets/btn-icons/icons8_back 1.png">
            </div>
            <div class="right" (click)="navigate('1')">
                <img src="./assets/btn-icons/icons8_next 2.png">
            </div>
        </div>

        <div class="slider">
            <div class="card card-pol-1"></div>
            <div class="card card-pol-2">

                <ng-container *ngIf="currentSlider.template === 'template1'">
                    <ng-container *ngTemplateOutlet="template1"></ng-container>
                </ng-container>

                <ng-container *ngIf="currentSlider.template === 'template2'">
                    <ng-container *ngTemplateOutlet="template3"></ng-container>
                </ng-container>

                <ng-container *ngIf="currentSlider.template === 'template3'">
                    <ng-container *ngTemplateOutlet="template3"></ng-container>
                </ng-container>
                

            </div>
            <div class="card card-pol-3"></div>
        </div>

    </div>


    <div class="info-area">

        <div class="slider">
            <app-common-slider #commonSlider *ngIf="appService.allAuctions.length" [sliderData]="sliderData"></app-common-slider>
        </div>

        <div class="img-area">

            <div class="txt">
                <div class="big-txt">
                    500+ Contractors
                </div>
                <div class="small-txt">
                    <img src="./assets/white-star.png"> 4.9 (5.8k review)
                </div>
            </div>

            <img src="./assets/house-info.png">

        </div>

    </div>

    <div class="rules-and-reg" ><div (click)="showRulesAndReg()">Read DreamStakes Rules and Regulations</div></div>

    <!-- showDialogUI -->
    <!-- <app-ds-contract [display]="showDialogUI" (notifyParent)="recieveFromPopupDialog($event)" [popupData]="currentPopupPage"></app-ds-contract> -->
    <!-- <app-popup [display]="showDialogUI" (notifyParent)="recieveFromPopupDialog($event)" [popupData]="currentPopupPage"></app-popup> -->
    <!-- <app-popup [display]="showTearms" (notifyParent)="recieveFromPopupDialog($event)" [popupData]="customPopupContents[0]"></app-popup>-->
    <!--<app-popup [display]="showPrivacyPolicy" (notifyParent)="recieveFromPopupDialog($event)" [popupData]="customPopupContents[1]"></app-popup>-->
    <!-- <app-popup [display]="showRulesAndReg" (notifyParent)="recieveFromPopupDialog($event)" [popupData]="dsRulesAndreg"></app-popup>  -->

    <app-new-contract-popup *ngIf="showContractPopup" [display]="showContractPopup" [popupData]="currentPopupPage" (closePopup)="closePopup($event)"></app-new-contract-popup>
    <app-add-card-popup [display]="showAddCardPopup" (closePopup)="closeCardPopup($event)"></app-add-card-popup>
    <app-rules-and-reg [display]="dsRulesAndreg" (closeRulesPopup)="closeRulesPopup($event)"></app-rules-and-reg>

    

    

</div>

<ng-template #template1>

    <img [src]="currentSlider?.coverimage" style="
        z-index: -1;
        position: absolute;
        left: 260px;
        top: 106px;
        width: 141px;
        height: 141px;
        object-fit: cover;"
    >

    <img style="width: 100%; height: 100%;" src="./assets/auction-templates/template-1.png">

    <div style="
        z-index: 10;
        position: absolute;
        top: 41px;
        left: 21px;
        color: #0093b2;
        font-weight: 600;
        font-size: 20px;
        font-family: Roboto;
        width: 200px;
        height: 77px;
        display: flex;
        align-items: center;"
    >{{currentSlider?.title || ""}}</div>

    <span style="
        z-index: 10;
        position: absolute;
        top: 229px;
        left: 77px;
        color: #f5b081;
        font-weight: 600;
        font-size: 15px;
        font-family: Roboto;"
    >{{currentSlider?.bidding_rooms || 0}}</span>


    <div style="
        z-index: 10;
        position: absolute;
        top: 254px;
        left: 357px;
        color: #05527ce0;
        font-weight: 600;
        font-size: 20px;
        font-family: Roboto;
        width: 75px;
        height: 27px;
        text-align: center;
        padding-top: 2px;
        padding-left: 14px;"
    >{{currentSlider?.enter_fee || 0}}</div>

</ng-template>

<ng-template #template2>

    <img [src]="currentSlider?.coverimage" style="
        z-index: -1;
        position: absolute;
        left: 22px;
        top: 126px;
        width: 219px;
        height: 168px;
        object-fit: cover;"
    >

    <img style="width: 100%; height: 100%;" src="./assets/auction-templates/template-2.png">

    <div style="
        z-index: 10;
        position: absolute;
        top: 52px;
        color: #0093b2;
        font-weight: 600;
        font-size: 17px;
        font-family: Roboto;
        width: 100%;
        height: 46px;
        align-items: center;
        text-align: center;"
    >{{currentSlider?.title || ""}}</div>

    <span style="
        z-index: 10;
        position: absolute;
        top: 206px;
        left: 429px;
        color: #1d9fbb;
        font-weight: 600;
        font-size: 15px;
        font-family: Roboto;"
    >{{currentSlider?.bidding_rooms || 0}}</span>


    <div style="
        z-index: 10;
        position: absolute;
        top: 164px;
        left: 386px;
        color: #0093b2;
        font-weight: 600;
        font-size: 26px;
        font-family: Roboto;
        width: 75px;
        height: 27px;
        padding-top: 2px;
        padding-left: 4px;"
    >{{currentSlider?.enter_fee || 0}}</div>

</ng-template>

<ng-template #template3>

    <img [src]="currentSlider?.coverimage" style="
        z-index: -1;
        position: absolute;
        left: 138px;
        top: 91px;
        width: 179px;
        height: 167px;
        object-fit: cover;"
    >

    <img style="width: 100%; height: 100%;" src="./assets/auction-templates/template-3.png">

    <div style="
        z-index: 10;
        position: absolute;
        top: 14px;
        left: 125px;
        font-weight: 600;
        font-size: 18px;
        font-family: Roboto;
        width: 40%;
        height: 46px;
        align-items: center;
        text-align: center;
        color: #efe83d;"
    >{{currentSlider?.title || ""}}</div>

    <span style="
        z-index: 10;
        position: absolute;
        top: 159px;
        left: 295px;
        color: #cf3817;
        font-weight: 600;
        font-size: 19px;
        font-family: Roboto;
        transform: rotate(-12deg);"
    >{{currentSlider?.bidding_rooms || 0}}</span>


    <div style="
        z-index: 10;
        position: absolute;
        top: 210px;
        left: 122px;
        color: #cf3d18;
        font-weight: 600;
        font-size: 20px;
        font-family: Roboto;
        width: 75px;
        height: 27px;
        padding-top: 2px;
        padding-left: 4px;
        transform: rotate(-41deg);"
    >{{currentSlider?.enter_fee || 0}}</div>

</ng-template>