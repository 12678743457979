import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ICard, IInvites, IPopupConfigs, IPopupCpmmands, KeyLockerViews, claimeStatus, auctionHouseType } from '../ds-components/ds-types';
import { GridBtnRendererComponent } from './grid-btn-renderer/grid-btn-renderer.component';
import { ExtapiService } from '../extapi.service';
import { ProfileService } from '../profile.service';
import * as moment from 'moment';
import { AppConfigService } from '../app-config.service';
import { MessageService } from 'primeng/api';


const minFordays = 1440;
const secForDays = 86400;
const minsForHr = 60;
const secForHrs = 3600;
const secsForMin = 60;

@Component({
  selector: 'app-key-locker',
  templateUrl: './key-locker.component.html',
  styleUrls: ['./key-locker.component.scss']
})
export class KeyLockerComponent implements OnInit, AfterViewInit{

  @ViewChild('activeAgGrid', { static: true }) activeAgGrid: AgGridAngular;
  @ViewChild('closeAgGrid', { static: true }) closeAgGrid: AgGridAngular;

  Views = KeyLockerViews;
  currentView: string = KeyLockerViews.entries;
  observeUI: boolean = false;
  reviewUI: boolean = false;

  frameworkComponents: any;

  activeColumnDefs = [
    {  
      headerName: '#ID',
      field: 'ID',
      width: 100,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Room Number',
      field: 'RoomNumber',
      width: 200,
      resizable: true,
      // sort: 'asc'
    },
    {  
      headerName: 'Highest Bid',
      field: 'HighestBid',
      width: 200,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Capacity Meter',
      field: 'CapacityMeter',
      width: 200,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Timer',
      field: 'Timer',
      width: 200,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: '',
      field: 'actBtn',
      width: 200,
      resizable: true,
      sort: 'asc',
      cellRendererFramework: GridBtnRendererComponent
    }
  ];

  activeRowData = [];

  closeColumnDefs = [
    {  
      headerName: '#ID',
      field: 'ID',
      width: 100,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Room Number',
      field: 'RoomNumber',
      width: 200,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Winning Bid',
      field: 'WinningBid',
      width: 200,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Total Bids',
      field: 'TotalBids',
      width: 200,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Closed',
      field: 'Closed',
      width: 200,
      resizable: true,
      sort: 'asc',
      cellRendererFramework: GridBtnRendererComponent,
      cellRendererParams: { 
        clickHandler: this.showReviewUI.bind(this)
      }
    },
    {  
      headerName: 'Claim Status',
      field: 'ClaimStatus',
      width: 200,
      resizable: true,
      sort: 'asc',
      cellRendererFramework: GridBtnRendererComponent
    }
  ]

  closeRowData = [];

  cashSpentColumnDefs = [
    {  
      headerName: '#ID',
      field: 'ID',
      width: 150,
      resizable: true
    },
    {  
      headerName: 'Room Number',
      field: 'RoomNumber',
      width: 300,
      resizable: true
    },
    {  
      headerName: 'Entry Date',
      field: 'EntryDate',
      width: 300,
      resizable: true
    },
    {  
      headerName: 'Payment',
      field: 'Payment',
      width: 300,
      resizable: true
    }
  ];

  cashSpentRowData = [];

  contractColumnDefs = [
    {  
      headerName: 'DATE',
      field: 'DATE',
      width: 175,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'FOR',
      field: 'FOR',
      width: 700,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'CLAIM',
      field: 'CLAIM',
      width: 200,
      resizable: true,
      sort: 'asc'
    }
  ];

  contractRowData = [
    {
      DATE: 'Nov 16, 2020',
      FOR: 'Single Auction House ( Funds Pending Clearance )',
      CLAIM: '$260'
    },
    {
      DATE: 'Nov 16, 2020',
      FOR: 'Single Auction House ( Funds Pending Clearance )',
      CLAIM: '$260'
    },
    {
      DATE: 'Nov 16, 2020',
      FOR: 'Single Auction House ( Funds Pending Clearance )',
      CLAIM: '$260'
    },
    {
      DATE: 'Nov 16, 2020',
      FOR: 'Single Auction House ( Funds Pending Clearance )',
      CLAIM: '$260'
    },
    {
      DATE: 'Nov 16, 2020',
      FOR: 'Single Auction House ( Funds Pending Clearance )',
      CLAIM: '$260'
    }

  ];

  bidPlacedtColumnDefs = [
    {  
      headerName: '#ID',
      field: 'ID',
      width: 150,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Entry Date',
      field: 'EntryDate',
      width: 300,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Auction Type',
      field: 'AuctionType',
      width: 300,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Bids (times)',
      field: 'Bids',
      width: 300,
      resizable: true,
      sort: 'asc'
    }
  ];

  bidPlacedRowData = [];

  cards: ICard[] = [
    {
      id: KeyLockerViews.entries,
      icon: './assets/dasboard/home.png',
      title: 'Entries',
      info: '35',
      infoIcon: '',
      background: './assets/key-locker/selected-background.png'
    },
    {
      id: KeyLockerViews.cashSpent,
      icon: './assets/dasboard/users.png',
      title: 'Cash Spent',
      info: '$3,435',
      infoIcon: ''
    },
    {
      id: KeyLockerViews.invites,
      icon: './assets/dasboard/users.png',
      title: 'Successful Invites',
      info: '189',
      infoIcon: ''
    },
    {
      id: KeyLockerViews.bidPlaced,
      icon: './assets/dasboard/settings.png',
      title: 'Bid Placed',
      info: '50',
      infoIcon: ''
    },
    {
      id: KeyLockerViews.contractWon,
      icon: './assets/dasboard/send.png',
      title: 'Contracts Won',
      info: '187',
      infoIcon: ''
    }
  ]

  invites: IInvites[] = [];

  // Charts
  chartData: any;
  chartOptions: any;
  chartHeight: number = 380;
  chartWidth: number = 1050;

  showEntriesGrid: boolean = true;
  showEntriesChart: boolean = false;
  showDuplexCharts: boolean = false;

  showAcutionChart: boolean = true;
  showAuctionGrid: boolean = true;

  showContractChart: boolean = true;
  showContractDetails: boolean = false;

  showBidPlacedChart: boolean = true;
  showBidPlacedGrid: boolean = false;

  popupData: IPopupConfigs[];

  getRegistedAuctionDetailsPerUserWithTimmerinfo;
  currentUser;
  allActiveAuctions = [];
  selectedAuctionId;
  selectedAuctionRoomNumber;
  allRegistredAuctions;
  allClosedAuctions = [];

  allAuctions;

  numberOfBidsPerRegistedAuctionArr;

  url = "www.dreamstakes.com/";
  emailsStr;
  emailsArr;

  selectedClosedAuctionId;
  selectedClosedAuctionRoomNumber;

  constructor(
    private cdr: ChangeDetectorRef,
    public extapiService: ExtapiService,
    public profileService: ProfileService,
    public appService: AppConfigService,
    private messageService: MessageService) { 
      
    this.chartData = {

      cashSpent: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: '#F9A369',
          label: 'Single Auction'
        },
        {
          data: [],
          backgroundColor: '#95CED1',
          label: 'Duplex Auction'
        },
        {
          data: [],
          backgroundColor: '#bced51',
          label: 'Commercial Auction'
        }]
      },
      contractsWon: {
        labels: ['Single Auction House', 'Duplex Auction House', 'Commercial Auction House'],
        datasets: [
          {
            label: 'My First dataset',
            backgroundColor: '#ED8646',
            borderColor: '#ED8646',
            data: [250, 450, 300]
          }
        ]

      },
      bids: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#95CED1",
              "#FCDA61",
              "#F9A369",
              '#EB9FA6',
              '#F6CBC6'
            ],
            hoverBackgroundColor: [
              "#95CED1",
              "#FCDA61",
              "#F9A369",
              '#EB9FA6',
              '#F6CBC6'
            ]
          }
        ]    
      },
      biddingRooms: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: '#F9A369',
          label: 'Single Auction'
        },
        {
          data: [],
          backgroundColor: '#95CED1',
          label: 'Duplex Auction'
        },
        {
          data: [],
          backgroundColor: '#bced51',
          label: 'Commercial Auction'
        }]
      },
      duplexChart1: {
        labels: ['08/2023', '09/2023', '10/2023', '11/2023', '12/2023', '13/2023', '14/2023'],
        datasets: [
          {
            data: [50, 150, 150, 200, 500, 500, 800],
            fill: false,
            borderColor: '#F9A369',
            pointBackgroundColor: '#72CDC2',
            pointBorderColor: '#72CDC2',
            lineTension: 0
          }
        ]

      },
      duplexChart2: {
        labels: ['08/2023', '09/2023', '10/2023', '11/2023', '12/2023', '13/2023', '14/2023'],
        datasets: [
          {
            data: [75, 225, 150, 500, 500, 500, 800],
            fill: false,
            borderColor: '#F9A369',
            pointBackgroundColor: '#72CDC2',
            pointBorderColor: '#72CDC2',
            lineTension: 0
          }
        ]

      }

    }

    this.chartOptions = {

      cashSpent: {
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 18,
            fontFamily: "Open Sans",
            fontSize: 12,
            fontWeight: 400,
          }
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              // padding: {
              //   left: 20
              // }
            },
            gridLines : {
              display : false
            }
          }]
        }
      },
      contractsWon: {
        legend: {display: false},
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            maxBarThickness: 40,
            gridLines : {
              display : false
            },
            ticks: {
              fontFamily: 'Roboto', 
              fontSize: 12, 
              fontWeight: 400,
            }
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Roboto', 
              fontSize: 12, 
              fontWeight: 400,
              beginAtZero: true
            }
          }]
        }
      },
      bids: {
        plugins: {
          // datalabels: {
          //   /* show value in percents */
          //   formatter: (value, ctx) => {
          //     let sum = 0;
          //     const dataArr = ctx.chart.data.datasets[0].data;
          //     dataArr.map(data => {
          //           sum += data;
          //     });
          //     const percentage = (value * 100 / sum); 
          //     return percentage !== 0 ? percentage.toFixed(2) + '%' : '';
          //   },
          //   color: 'red',
          //   align: 'top'
          // },

          
        },
        legend: {
          position: 'right',
          labels: {
            boxWidth: 18,
            fontFamily: "Open Sans",
            fontSize: 16,
            fontWeight: 400,
          }
        }

      },
      biddingRooms : {
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 18,
            fontFamily: "Open Sans",
            fontSize: 12,
            fontWeight: 400,
          }
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              // padding: {
              //   left: 20
              // }
            },
            gridLines : {
              display : false
            }
          }]
        }
      },
      duplexChart1: {
        legend: {display: false},
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              // padding: {
              //   left: 20
              // }
            },
            gridLines : {
              display : false
            }
          }]
        }
      },
      duplexChart2: {
        legend: {display: false},
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              // padding: {
              //   left: 20
              // }
            },
            gridLines : {
              display : false
            }
          }]
        }
      }

    }

  }

  async ngOnInit(){

    this.popupData = [
      {
        header: 'Observe',
        contentHeader: 'The observe window is a detailed window of the auction. And you can see last active 10 bidders details.',
        content: ``,
        bckButton: false,
        nxtButton: false,
        customContents: 'observe',
        showFooter: false,
        nxtButtonDis: false
      },
      {
        header: 'Review',
        contentHeader: '',
        content: ``,
        bckButton: false,
        nxtButton: false,
        customContents: 'review',
        showFooter: false,
        nxtButtonDis: false
      }
    ]

    this.currentUser = this.profileService.currentUser;

    this.allRegistredAuctions = await this.extapiService.getAllRegisteredAuctionsDetailsPerUser({clientId: this.currentUser.client_id});
    
    this.cards[0].info = this.allRegistredAuctions.length;
    this.cards[1].info = "$" + this.allRegistredAuctions.reduce((a, c) => {return a + c.enter_fee}, 0).toLocaleString();

    let allBids = await this.extapiService.getBidsByClientID({client_id: this.currentUser.client_id});
    this.cards[3].info = allBids.length;

    let WinningDetailsArr = await this.extapiService.getWinningDetailsPerUser({client_id: this.profileService.currentUser.client_id});
    this.cards[4].info  = WinningDetailsArr.length;

    // Sort by date - latest record will be 0 th position
    this.allRegistredAuctions.sort((a, c) => {

      let date1 = new Date(moment(a.date.split("T")[0]).format("MM-DD-YYYY")).getTime();
      let date2 = new Date(moment(c.date.split("T")[0]).format("MM-DD-YYYY")).getTime();

      return (date2 - date1);

    });

    //number of bids per registed auction
    this.numberOfBidsPerRegistedAuctionArr = await this.extapiService.getNumberOfBidsPerUser({clientId: this.profileService.currentUser.client_id});
    
    this.showCharts();
    this.showGridsData();

    //set contracts Won bar chart data
    this.setContractWonChartData(WinningDetailsArr);

  }

  async ngAfterViewInit(){

    this.getRegistedAuctionDetailsPerUserWithTimmerinfo = await this.extapiService.getRegistedAuctionDetailsPerUserWithTimmerinfo({client_id:this.currentUser.client_id});
    await this.setGridData();
    
    
    this.activeRowData = this.allActiveAuctions;

    this.closeRowData = this.allClosedAuctions;
    

  }

  private async showCharts(){

    // Cash Spent ------------------------------
    this.showCashSpentChart();
    this.showEntriesDataChart();


    //bids
    this.showBidPlacedDataChart();
    this.showBidPlacedDataGrid();



  }

  private showCashSpentChart(){

    let endDate = moment().format(); // today
    let startDate = moment(endDate).add(-12, 'months').format("MM-DD-YYYY"); // 12 months back
    let nxtMonth = moment(startDate).add(1, 'months').format("MM-DD-YYYY");
    let chartLabels = [];

    for(let i = 0; i < 12; i++){
      chartLabels.push(moment().add(-i, "months").format("MMMM"))
    }

    // Filter last 12 months data
    let last12Mosdata = this.allRegistredAuctions.filter((anAuction) => {

      let date = moment(anAuction.date.split("T")[0]).format("MM-DD-YYYY");

      if(moment(date).isSameOrAfter(moment(startDate)))
        return anAuction;

    });

    let singleAuctionData = [];
    let duplexAuctionData = [];
    let comAuctionData = [];

    last12Mosdata.forEach((data) => {

      let date = moment(data.date.split("T")[0]).format("MM-DD-YYYY");

      if(moment(date).isSameOrAfter(moment(startDate)) && moment(date).isSameOrBefore(moment(nxtMonth)) || 
        moment(date).isSameOrAfter(moment(startDate)) && moment(date).isSameOrBefore(moment(endDate))){

        let month = moment(date).format("MMMM"); // months are 0 based

        // Check the type
        switch(data.category){

          case auctionHouseType.single:{

            let dataIdx = singleAuctionData.findIndex(e => e.month === month);

            if(dataIdx < 0)
              singleAuctionData.push({month: month, amount: data.enter_fee})
            else
              singleAuctionData[dataIdx].amount += data.enter_fee;
    
          }
          break;
          case auctionHouseType.duplex:{

            let dataIdx = duplexAuctionData.findIndex(e => e.month === month);

            if(dataIdx < 0)
              duplexAuctionData.push({month: month, amount: data.enter_fee})
            else
              duplexAuctionData[dataIdx].amount += data.enter_fee;

          }
          break;
          case auctionHouseType.comercial: {

            let dataIdx = comAuctionData.findIndex(e => e.month === month);

            if(dataIdx < 0)
              comAuctionData.push({month: month, amount: data.enter_fee})
            else
              comAuctionData[dataIdx].amount += data.enter_fee;

          }
          break;
        }


        nxtMonth = moment(nxtMonth).add(1, 'months').format("MM-DD-YYYY");

      }

    })

    this.chartData.cashSpent.labels = chartLabels.reverse();
    chartLabels.forEach(month => {

      this.chartData.cashSpent.datasets[0].data.push(singleAuctionData.filter(auction => auction.month === month)[0]?.amount || 0);
      this.chartData.cashSpent.datasets[1].data.push(duplexAuctionData.filter(auction => auction.month === month)[0]?.amount || 0);
      this.chartData.cashSpent.datasets[2].data.push(comAuctionData.filter(auction => auction.month === month)[0]?.amount || 0);


    });

  }

  private showEntriesDataChart(){

    let endDate = moment().format(); // today
    let startDate = moment(endDate).add(-12, 'months').format("MM-DD-YYYY"); // 12 months back
    let nxtMonth = moment(startDate).add(1, 'months').format("MM-DD-YYYY");
    let chartLabels = [];

    for(let i = 0; i < 12; i++){
      chartLabels.push(moment().add(-i, "months").format("MMMM"))
    }

    // Filter last 12 months data
    let last12Mosdata = this.allRegistredAuctions.filter((anAuction) => {

      let date = moment(anAuction.date.split("T")[0]).format("MM-DD-YYYY");

      if(moment(date).isSameOrAfter(moment(startDate)))
        return anAuction;

    });

    let singleAuctionData = [];
    let duplexAuctionData = [];
    let comAuctionData = [];

    last12Mosdata.forEach((data) => {

      let date = moment(data.date.split("T")[0]).format("MM-DD-YYYY");

      if(moment(date).isSameOrAfter(moment(startDate)) && moment(date).isSameOrBefore(moment(nxtMonth)) || 
        moment(date).isSameOrAfter(moment(startDate)) && moment(date).isSameOrBefore(moment(endDate))){

        let month = moment(date).format("MMMM"); // months are 0 based

        // Check the type
        switch(data.category){

          case auctionHouseType.single:{

            let dataIdx = singleAuctionData.findIndex(e => e.month === month);

            if(dataIdx < 0)
              singleAuctionData.push({month: month, count: 1})
            else
              singleAuctionData[dataIdx].count++;
    
          }
          break;
          case auctionHouseType.duplex:{

            let dataIdx = duplexAuctionData.findIndex(e => e.month === month);

            if(dataIdx < 0)
              duplexAuctionData.push({month: month, count: 1})
            else
              duplexAuctionData[dataIdx].count++;

          }
          break;
          case auctionHouseType.comercial: {

            let dataIdx = comAuctionData.findIndex(e => e.month === month);

            if(dataIdx < 0)
              comAuctionData.push({month: month, count: 1})
            else
              comAuctionData[dataIdx].count++;

          }
          break;
        }


        nxtMonth = moment(nxtMonth).add(1, 'months').format("MM-DD-YYYY");

      }

    })

    this.chartData.biddingRooms.labels = chartLabels.reverse();
    chartLabels.forEach(month => {

      this.chartData.biddingRooms.datasets[0].data.push(singleAuctionData.filter(auction => auction.month === month)[0]?.count || 0);
      this.chartData.biddingRooms.datasets[1].data.push(duplexAuctionData.filter(auction => auction.month === month)[0]?.count || 0);
      this.chartData.biddingRooms.datasets[2].data.push(comAuctionData.filter(auction => auction.month === month)[0]?.count || 0);


    });

  }


  private showGridsData(){

    this.showCashSpentGrid();


  }

  private async showCashSpentGrid(){
    

    
    this.allRegistredAuctions.forEach(regAuction => {

      // Single
      let noOfRooms = 1;
      
      // Duplex
      if(regAuction.category === 2)
        noOfRooms = 2;

      // Commercial
      if(regAuction.category === 3)
        noOfRooms = 4;

      this.cashSpentRowData.push({
        ID: regAuction.auction_id,
        RoomNumber: regAuction.auction_id +'.' + regAuction.room_number + '-' + noOfRooms,
        EntryDate: moment(regAuction.date).format("MM-DD-YYYY"),
        Payment: '$' + regAuction.enter_fee
      })

    })

   
   

  }

  private async showBidPlacedDataChart(){

    //===========bids chart data==================

    //get all auctions
    this.allAuctions = this.appService.allAuctions;

    
    this.allAuctions.filter((anAuction) => {

      this.numberOfBidsPerRegistedAuctionArr.forEach((el) => {

        if(el.auction_id  === anAuction.auction_id){

          this.chartData.bids.labels.push(anAuction.title);
          this.chartData.bids.datasets[0].data.push(el.count);
        }
        
      })

    });

  }

  private showBidPlacedDataGrid(){

    this.allAuctions.filter((anAuction) => {

      let entryDate;
      let category;

      this.allRegistredAuctions.forEach((elm) => {

        if(elm.auction_id  === anAuction.auction_id){

          //set entry date
          entryDate = moment(elm.date).format("MMM DD, YYYY");

          //set auction type
          switch (anAuction.category) {
            case auctionHouseType.single:

              category = "Single"
              
              break;

            case auctionHouseType.duplex:
              
              category = "Duplex"
              
              break;
          
          
            case auctionHouseType.comercial:
              
              category = "Comercial"
              
              break;
          
          }

          this.numberOfBidsPerRegistedAuctionArr.forEach((el) => {

            if(el.auction_id  === elm.auction_id){
  
              this.bidPlacedRowData.push({
                ID: el.auction_id,
                EntryDate: entryDate,
                AuctionType: category,
                Bids: el.count
  
              })
  
            }
            
          });

        }
        

      });

    });

    console.log(this.allAuctions)

  }

  
  showReviewUI(event){
    this.selectedClosedAuctionId = event.data.ID;
    this.selectedClosedAuctionRoomNumber = +event.data.RoomNumber.split(".")[1].split("-")[0];
    this.reviewUI = true;
  }

  viewChange(aCard){

    this.currentView = aCard.id;

    // Set inner view
    switch(aCard.id){

      case KeyLockerViews.entries: this.changeInternalViews('entriesGrid'); break;
      case KeyLockerViews.cashSpent: this.changeInternalViews('auctionChart'); break;
      case KeyLockerViews.contractWon: this.changeInternalViews('contractChart'); break;
      
    }

    this.cards.forEach(el => {

      if(el.id === aCard.id)
        el.background = './assets/key-locker/selected-background.png';
      else
        el.background = ''
    })


  }

  changeInternalViews(viewName){

    switch(viewName){

      case 'entriesGrid': 
        this.showEntriesGrid = true;
        this.showEntriesChart = false;
        this.showDuplexCharts = false;
      break;

      case 'entriesChart':
        this.showEntriesChart = true
        this.showEntriesGrid = false;
        this.showDuplexCharts = false;
      break;

      case 'duplexCharts': 
        this.showDuplexCharts = true;
        this.showEntriesChart = false;
        this.showEntriesGrid = false;
      break;

      case 'auctionChart':
        this.showAcutionChart = true;
        this.showAuctionGrid = false;
      break;

      case 'auctionGrid':
        this.showAcutionChart = false;
        this.showAuctionGrid = true;
      break;

      case 'contractChart':
        this.showContractChart = true;
        this.showContractDetails = false;
      break;

      case 'contractGrid':
        this.showContractDetails = true;
        this.showContractChart = false;
      break;

      case 'showBidPlacedChart':
        this.showBidPlacedChart = true;
        this.showBidPlacedGrid = false;
      break;

      case 'showBidPlacedGrid':
        this.showBidPlacedGrid = true;
        this.showBidPlacedChart = false;
      break;

    }

    this.cdr.detectChanges();

  }

  recieveFromPopupDialog(e){
    
    switch(e.command){

      case IPopupCpmmands.close:
 
        this.observeUI = false;
        this.reviewUI = false;

      break;
      
    }

    

  }

  async setGridData(): Promise<any>{

    for(let auction of this.getRegistedAuctionDetailsPerUserWithTimmerinfo){

      //set height bid value =====active auction table
      let highestBid = await this.extapiService.getHighestBidforAuction({auctionId: auction.auction_id, current_room: auction.room_number});

      //set capacity meter value =====active auction table
      let registedUsersDetails = await this.extapiService.getNumbersOfUsersForRegistedAuction({auctionId: auction.auction_id});
      let CapacityMeterCount = ((registedUsersDetails[0].NumberOfUsers/auction.entrants)* 100).toFixed(2);

      //set timmer value ===== active auction table
      let getEndedDateTime = await this.extapiService.getEndedTime({auction_id: auction.auction_id, room_number: auction.room_number});
      let endedDateTime = moment(getEndedDateTime[0].ended_time).format(); 
      let now = moment().format();
      let {hr, min, sec} = this.getTimer(endedDateTime, now);

      
      //check auction is active  or close
      if(moment(endedDateTime).isBefore(now)){

        //set total bids =====closed auction table
        let totalBidsArr = await this.extapiService.getNumbersOfBidsForAuction({auctionId: auction.auction_id, current_room: auction.room_number});
        let totalBids = totalBidsArr[0]?.NumberOfBids;

        //wining bid value and clain satatus =====closed auction table
        let winingDetails = await this.extapiService.getWinningBidInfo({auction_id: auction.auction_id, room_num: auction.room_number});
        
        let winningBid = winingDetails[0]?.winning_bid || 0;
        let claimStatus = winingDetails[0]?.claime_status;
        let claimStatusLbl;
        
        switch (claimStatus) {

          case claimeStatus.pending:

            claimStatusLbl = 'Pending';
            break;
        
          case claimeStatus.claimed:

            claimStatusLbl = 'Claimed';
            break;
            

          default:

            claimStatusLbl = 'Pending';
            break;

        }

        this.allClosedAuctions.push({
          ID: auction.auction_id,
          RoomNumber: auction.auction_id +'.' + auction.room_number + '-' + auction.bidding_rooms,
          WinningBid: winningBid,
          TotalBids: totalBids,
          Closed: '',
          ClaimStatus: claimStatusLbl
        });

      }

      else{
      
        this.allActiveAuctions.push({
          ID: auction.auction_id,
          RoomNumber: auction.auction_id +'.' + auction.room_number + '-' + auction.bidding_rooms,
          HighestBid: highestBid[0].heighestBid || 0,
          CapacityMeter: CapacityMeterCount,
          Timer: hr + ":" + min + ":" + sec
        });

      }

    }


  }

  private getTimer(endedDateTime, now){

    let hr, min, sec;

    hr = moment(endedDateTime).diff(now, 'hours');

    //--------------------- Calc mins -------------------------
    let minsDiff = moment(endedDateTime).diff(now, 'minutes');

    //Reduce no. of days and calc no. of mins
    let days = Math.floor(minsDiff / minFordays);
    let mins = minsDiff - (minFordays * days);

    //Reduce hrs
    let hrs = Math.floor(mins / minsForHr);
    min = mins - (minsForHr * hrs);

    //--------------------------------------------------------

    //--------------------- Calc seconds ---------------------
    let secondsDiff = moment(endedDateTime).diff(now, 'seconds');

    //Reduce no. of days
    days = Math.floor(secondsDiff / secForDays);
    let secs = secondsDiff - (secForDays * days);

    //Reduce hrs
    hrs = Math.floor(secs / secForHrs);
    secs = secs - (secForHrs * hrs);

    //reduce mins
    mins = Math.floor(secs / secsForMin);
    sec = secs - (secsForMin * mins);

    return {hr, min, sec};

  }

  onCellClicked(event){
    this.selectedAuctionId = event.data.ID;
    this.selectedAuctionRoomNumber = +event.data.RoomNumber.split(".")[1].split("-")[0];
    this.observeUI = true;
  }

  setContractWonChartData(WinningDetailsArr){

    let winCountSingle = [];
    let winCountDuplex = [];
    let winCountCommerial =[];

    WinningDetailsArr.forEach(element => {
      switch (element.title) {

        case "Single Auction":
          winCountSingle.push(element);
          
          break;

        case "Duplex Auction":
          winCountDuplex.push(element);
          
          break;

        case "Commercial Auction":
          winCountCommerial.push(element);
          
          break;
      
        
      }
     
      
    });
    this.chartData.contractsWon.datasets[0].data =[winCountSingle.length,winCountDuplex.length,winCountCommerial.length]

  }

  copyLinkToClipboard(){

    this.messageService.add({ key: 'br', severity: 'success', summary: 'Success', detail: 'Copied to Clipbord' });
    
  }

  sendInvitation(){

    this.emailsArr = this.emailsStr.split(',').map(el => el.trim());
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if(this.emailsArr.some(el => !emailRegex.test(el)))
      alert("Invalid email address found");

    else{

      this.emailsArr.forEach(el => {

        if(!this.invites.length || this.invites.findIndex(mailObj => mailObj.email === el) < 0)
          this.invites.push({
            email: el,
            status: 'Pending'
          })

      });

      console.log(this.invites);
      this.emailsStr = "";

    }


  }

}